<template>
    <div style="width: 99%;" :style="addPadding !== null ? 'padding-left:'+addPadding+'px; padding-right:'+addPadding+'px;' : ''">
        <b-row>
            <!--Start Date/Time-->
            <b-col :sm="stacked === true ?'12' : '6'">
                <div :style="date.errorStart === null && date.errorEnd === null ? 'padding-bottom: 18px;' : ''"
                     :class="centerDateTime ? 'datePickerScroll' : ''">
                    <b>{{startTimeName}}</b>
                    <date-picker :class="(!date.start || date.errorStart) && noErrors === false ? 'redBorder' : ''"
                                 v-model="date.start" :config="date.configStartTime" @input="startDateSelected"/>
                </div>
                <div v-if="date.errorStart && noErrors === false" class="col-12 errorText" style="font-size: 12px;">
                    {{date.errorStart}}
                </div>
            </b-col>
            <!--End Date/Time-->
            <b-col :sm="stacked === true ?'12' : '6'">
                <div :style="date.errorStart === null && date.errorEnd === null ? 'padding-bottom: 18px;' : ''"
                     :class="centerDateTime ? 'datePickerScroll' : ''">
                    <b>{{endTimeName}}</b>
                    <date-picker :class="(!date.end || date.errorEnd) && noErrors === false ? 'redBorder' : ''"
                                 v-model="date.end" :config="date.configEndTime" @input="endDateSelected"/>
                </div>
                <div v-if="date.errorEnd && noErrors === false" class="col-12 errorText" style="font-size: 12px;">
                    {{date.errorEnd}}
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import datePicker from "vue-bootstrap-datetimepicker";
import * as moment from "moment/moment";

export default {
    name: "date-range-select",
    components: {
        datePicker
    },
    props: {
        stacked: {
            type: Boolean,
            default: true
        },
        noFuture: {
            type: Boolean,
            default: true
        },
        retention: {
            type: Number,
            default: 30
        },
        startDate: {
            type: String,
            default: null
        },
        endDate: {
            type: String,
            default: null
        },
        timeZone: {
            type: Boolean,
            default: false
        },
        addPadding: {
            type: Number,
            default: null
        },
        startTimeName: {
            type: String,
            default: 'Start Date/Time:'
        },
        endTimeName: {
            type: String,
            default: 'End Date/Time:'
        },
        rangeLimit: {
            type: Number,
            default: null
        },
        noErrors: {
            type: Boolean,
            default: false
        },
        centerDateTime: {
            type: Boolean,
            default: false
        },
        startTimeWidgetPositionVertical: {
            type: String,
            default: 'auto'
        },
        startTimeWidgetPositionHorizontal: {
            type: String,
            default: 'auto'
        },
        endTimeWidgetPositionVertical: {
            type: String,
            default: 'auto'
        },
        endTimeWidgetPositionHorizontal: {
            type: String,
            default: 'auto'
        }

    },
    data() {
        return {
            date: {
                min: null,
                start: null,
                end: null,
                max: null,
                errorEnd: null,
                errorStart: null,
                configStartTime: {
                    format: 'MM/DD/YYYY h:mm:ss a ' + (this.timeZone === true ? 'Z' : ''),
                    useCurrent: false,
                    maxDate: this.noFuture === true ? Date.now() + 10000 : null,
                    showClear: true,
                    widgetPositioning: {
                        horizontal: this.startTimeWidgetPositionHorizontal,
                        vertical: this.startTimeWidgetPositionVertical
                    }
                },
                configEndTime: {
                    format: 'MM/DD/YYYY h:mm:ss a ' + (this.timeZone === true ? 'Z' : ''),
                    useCurrent: false,
                    maxDate: this.noFuture === true ? Date.now() + 10000 : null,
                    showClear: true,
                    widgetPositioning: {
                        horizontal: this.endTimeWidgetPositionHorizontal,
                        vertical: this.endTimeWidgetPositionVertical
                    }
                },
            },
            interval: null
        }
    },
    created() {
        this.updateTime();
        this.interval = setInterval(() => this.updateTime(), 1000);
        if (this.startDate !== null) {
            this.date.start = this.startDate;
        }
        if (this.endDate !== null) {
            this.date.end = this.endDate;
        }
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
    watch: {
        startDate() {
            this.date.start = this.startDate;
        },
        endDate() {
            this.date.end = this.endDate;
        }
    },
    methods: {
        startDateSelected(date) {
            this.$emit('startDate', date);
        },
        endDateSelected(date) {
            this.$emit('endDate', date);
        },
        updateTime() {
            this.date.max = Date.now();
            this.date.min = this.date.max - (this.retention * 86400 * 1000);
            let rangeLimit = false;
            if (this.rangeLimit && this.end - this.start >= this.rangeLimit) {
                rangeLimit = "Range needs to be under " + this.millisecondsToHourMinuteSecond(this.rangeLimit);
            }
            if (!this.end) {
                this.date.errorEnd = null;
            } else if (this.date.max < this.end && this.noFuture) {
                this.date.errorEnd = "Cant set time is in the future";
            } else if (this.date.min > this.end) {
                this.date.errorEnd = "Cant set time past retention";
            } else if (this.start !== null && this.start >= this.end) {
                this.date.errorEnd = "Cant set time before Start Date/Time";
            } else if (rangeLimit) {
                this.date.errorEnd = rangeLimit;
            } else {
                this.date.errorEnd = null;
            }
            if (!this.start) {
                this.date.errorStart = null;
            } else if (this.date.max < this.start && this.noFuture) {
                this.date.errorStart = "Cant set time is in the future";
            } else if (this.date.min > this.start) {
                this.date.errorStart = "Cant set time past retention";
            } else if (this.end !== null && this.end <= this.start) {
                this.date.errorStart = "Cant set time after End Date/Time";
            } else if (rangeLimit) {
                this.date.errorStart = rangeLimit;
            } else {
                this.date.errorStart = null;
            }
            this.$emit('isValid', !!(!this.date.end || this.date.errorEnd || !this.date.start || this.date.errorStart))
        },
        makeDateObj(e) {
            if (e) {
                if (typeof e === "string") {
                    return moment(e, 'MM/DD/YYYY h:mm:ss a ' + (this.timeZone === true ? 'Z' : '')).toDate();
                } else {
                    return e;
                }
            } else {
                return null;
            }
        },
        millisecondsToHourMinuteSecond(time) {
            const hours = Math.floor(time / (1000 * 60 * 60));
            const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((time % (1000 * 60)) / 1000);
            return (`${hours ? hours + 'h ' : ''}${minutes || (hours && seconds) ? minutes + 'm ' : ''}${seconds ? seconds + 's' : ''}`).trim();
        }
    },
    computed: {
        start() {
            return new Date(this.date.start).valueOf();
        },
        end() {
            return new Date(this.date.end).valueOf();
        },
    },
}
</script>

<style scoped>
.redBorder {
    border: 1px solid red;
}
</style>