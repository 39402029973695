<template style="overflow: hidden">
    <div :id="'mSidebarContainer-' + id" class="mSidebarContainer">
        <div :style="'width: ' + width" style="height: 100%; position: relative; overflow: hidden">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import {v4 as uuidv4} from "uuid";

export default {
    name: "m-sidebar",
    props: {
        width: {
            type: String,
            default: "325px"
        },
        isOpen: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            id: uuidv4()
        }
    },
    mounted() {
        const container = document.getElementById('mSidebarContainer-' + this.id)
        this.$nextTick(() => {
            if (this.isOpen) {
                container.style.width = this.width;
            }
        })
    },
    methods: {
        horizontalCollapse() {
            const container = document.getElementById('mSidebarContainer-' + this.id);
            const closing = container.offsetWidth > 0;
            const animationDuration = 300;
            const width = parseInt(this.width)

            let startTime = null;

            const animate = timestamp => {
                if (!startTime) startTime = timestamp;
                const elapsedTime = timestamp - startTime;

                if (elapsedTime < animationDuration) {
                    const progress = elapsedTime / animationDuration;
                    const direction = closing ? -1 : 1;
                    const newPos = width + progress * width * direction;

                    container.style.width = newPos + 'px';
                    requestAnimationFrame(animate);
                } else {
                    container.style.width = closing ? '0' : this.width;
                }
            };

            requestAnimationFrame(animate);
        },
    },
    watch: {
        isOpen(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.horizontalCollapse()
            }
        }
    }
}
</script>

<style scoped>
.mSidebarContainer {
    height: 100%;
    transition: all 0.3s;
    width: 0;
    overflow: hidden;
}
</style>