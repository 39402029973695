<template>
    <div>

        <!-- Edit/Add Modal -->
        <b-modal v-model="addEditPlate.modal" v-if="addEditPlate.modal" :title="(this.addEditPlate.data.id ? 'Edit' : 'Add') + ' Device'">
            <div style="margin-bottom: 10px">
                <div>Plate:</div>
                <b-form-input v-model="addEditPlate.data.plate" size="sm"/>
            </div>
            <div size="sm">
                <div>Description:</div>
                <b-form-input v-model="addEditPlate.data.reason" size="sm"/>
            </div>
            <template slot="modal-footer" class="w-100 d-flex justify-content-between">
                <div class="w-100 d-flex justify-content-between">
                    <b-button @click="deletePlate" size="sm" variant="danger"
                              v-if="this.addEditPlate.data.id">
                        Delete
                    </b-button>
                    <div v-else/>
                    <div>
                        <b-button @click="addEditPlate.modal = false" size="sm" variant="secondary">
                            Cancel
                        </b-button>
                        <b-button @click="putPostPlate" size="sm" variant="primary" style="margin-left: 6px"
                                  :disabled="disableSubmit">
                            Submit
                        </b-button>
                    </div>
                </div>
            </template>
        </b-modal>

        <!-- Title/Add -->
        <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 10px; width: 100%; cursor: pointer;">

            <!-- Title -->
            <div class="d-flex">
                <h3 style="margin: 0">Hot List:</h3>
            </div>

            <!-- Add Hot List Entry -->
            <div class="mBadge" @click="openAddPlate()">
                <fa-icon :icon="['fas', 'plus']"/> Add Entry
            </div>

        </div>

        <!-- Hot List Table -->
        <v-client-table style="width: 100%" class="dataTable" :columns="hotListTable.columns" :data="hotList"
                        :options="hotListTable.options" :theme="hotListTable.theme">
            <div slot="h__Edit" style="float: right">
                Edit
            </div>
            <div slot="Edit" slot-scope="props" style="float: right">
                <b-button variant="dark" size="sm" class="iconButton" @click="openAddPlate(props.row)">
                    <fa-icon :icon="['fas', 'pen-to-square']" size="lg"/>
                </b-button>
            </div>
            <div slot="createdAt" slot-scope="props">
                {{(new Date(props.row.createdAt * 1000).toLocaleString()) }}
            </div>
            <div slot="plate" slot-scope="props">
                <div style="color: #9cc4ff; cursor: pointer" title="Search Plate"
                     @click="$emit('searchPlate', props.row.plate)">
                    {{props.row.plate}}
                </div>
            </div>
        </v-client-table>

    </div>
</template>

<script>
import {ClientTable} from 'vue-tables-2';

export default {
    name: "HotListModal",
    components: {
        ClientTable,
    },
    props: {
        hotList: {
            type: Array,
            required: true
        },
        ssLPR: {
            type: Object,
            required: true
        },
        value: {
            type: String,
        }
    },
    data() {
        return {
            addEditPlate: {
                modal: false,
                data: null,
            },
            hotListTable: {
                columns: ['reason', 'plate', 'createdAt', 'createdBy', 'Edit'],
                options: {
                    sortable: ['reason', 'plate', 'createdAt', 'createdBy'],
                    orderBy: {column: 'createdAt', ascending: false},
                    headings: {
                        'reason': 'Description',
                        'plate': 'Plate Number',
                        'createdAt': 'Created Date',
                        'createdBy': 'Created By'
                    },
                    filterable: false,
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 10,
                    skin: 'table table-striped table-hover',
                    perPageValues: [],
                },
                theme: 'bootstrap4'
            }
        }
    },
    created() {
        this.handleValue();
    },
    methods: {
        openAddPlate(data) {
            if (data) {
                this.addEditPlate.data = data;
            } else {
                this.$set(this.addEditPlate, 'data', {});
                this.$set(this.addEditPlate.data, 'plate', '');
                this.$set(this.addEditPlate.data, 'reason', '');
            }
            this.addEditPlate.modal = true;
        },
        async putPostPlate() {
            if (this.addEditPlate.data.id) {
                await this.ssLPR.updateLprHot(this.addEditPlate.data);
            } else {
                await this.ssLPR.addToHotList(this.addEditPlate.data.plate, this.addEditPlate.data.reason);
            }
            this.$emit('updateHotList');
            this.addEditPlate.modal = false;
        },
        async deletePlate() {
            await this.ssLPR.removeFromHotList(this.addEditPlate.data)
            this.addEditPlate.modal = false;
            this.$emit('updateHotList');
        },
        handleValue() {
            if (this.value) {
                this.$set(this.addEditPlate, 'data', {});
                this.$set(this.addEditPlate.data, 'plate', this.value);
                this.$set(this.addEditPlate.data, 'reason', '');
                this.addEditPlate.modal = true;
                this.$emit('input', null);
            }
        }
    },
    watch: {
        value() {
            this.handleValue();
        }
    },
    computed: {
        disableSubmit() {
            return !this.addEditPlate.data.plate || !this.addEditPlate.data.reason;
        }
    }
}
</script>

<style scoped>
.mBadge {
    background-color: #039b0b;
    border-radius: 0.3rem;
    padding: 0 6px;
}
.iconButton {
    height: 29px !important;
    width: 29px !important;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.iconButton:not(:hover) {
    background-color: #131313 !important;
}
</style>