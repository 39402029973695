<template>
    <div>
        <div v-if="hitData !== null" class="plateOverviewContainer">

            <!-- Header -->
            <div class="headerContainer">
                <div class="d-flex">
                    <div class="headerTitleText">Plate Overview</div>
                    <b-badge variant="danger" v-if="isHotListHit" style="height: fit-content">
                        Hot List Hit
                    </b-badge>
                </div>
                <div class="closeCircle">
                    <fa-icon :icon="['fas', 'close']" class="closeIcon" @click="$emit('close')"/>
                </div>
            </div>

            <!-- Body -->
            <Transition name="dataChange">
                <div class="bodyContainer" v-if="!switching">
                    <div>
                        <div class="imgContainer" @click="imageClicked">
                            <fa-icon :icon="['fas', 'expand']"
                                     style="position: absolute; bottom: 5px; right: 5px; font-size: 24px; color: white; cursor: pointer;"/>
                            <img slot="base64Image" :src="lprImage(hitData.base64Image)" class="plateImg" alt="plate"
                                 style="cursor: pointer;" :style="isHotListHit ? 'border: 2px solid rgb(166,18,40)' : ''"/>
                        </div>
                        <b-row>
                            <b-col sm="6" class="bold">Camera:</b-col>
                            <b-col sm="6" class="value">{{ hitData.cameraName }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="6" class="bold">State:</b-col>
                            <b-col sm="6" class="value">{{ hitData.carState }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="6" class="bold">Plate Number:</b-col>
                            <b-col sm="6" class="value">{{ hitData.plateNumber }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="6" class="bold">Make:</b-col>
                            <b-col sm="6" class="value">{{ hitData.make }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="6" class="bold">Model:</b-col>
                            <b-col sm="6" class="value">{{ hitData.model }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="6" class="bold">Color:</b-col>
                            <b-col sm="6" class="d-flex align-items-center" v-if="hitData.color">
                                <div style="height: 12px; width: 12px; border: 1px solid rgb(202, 202, 202)"
                                     :style="'background-color: ' + hitData.color.toLowerCase()"/>
                                <div class="value" style="line-height: 0">&nbsp;{{ hitData.color }}</div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="6" class="bold">Speed:</b-col>
                            <b-col sm="6" class="value">{{ hitData.speed === '-1' ? '' : hitData.speed }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="6" class="bold">Confidence:</b-col>
                            <b-col sm="6" class="value">{{ hitData.confidence ? hitData.confidence + '%' : ''}}</b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="6" class="bold">Created Date:</b-col>
                            <b-col sm="6" class="value">
                                {{ hitData.timeStamp ? (new Date(hitData.timeStamp * 1000).toLocaleDateString()) : ''}}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="6" class="bold">Created Time:</b-col>
                            <b-col sm="6" class="value">
                                {{ hitData.timeStamp ? (new Date(hitData.timeStamp * 1000).toLocaleTimeString()) : ''}}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="6" class="bold">Latitude:</b-col>
                            <b-col sm="6" class="value">
                                {{ (hitData.latitude !== null && hitData.latitude !== '0') ? hitData.latitude : ''}}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm="6" class="bold">Longitude:</b-col>
                            <b-col sm="6" class="value">
                                {{ (hitData.longitude !== null && hitData.longitude !== '0') ? hitData.longitude : ''}}
                            </b-col>
                        </b-row>
                    </div>
                    <div v-if="hitData.latitude !== null && hitData.longitutde !== null && hitData.latitude !== '0' && hitData.longitutde !== '0'"
                         class="d-flex flex-wrap" style="margin: 10px 0 10px 0; flex: 1">
                        <gmap-map :center="curDeviceCoordinates" :map-type-id="'satellite'" ref="deviceLocationMap"
                                  :zoom="16" style="height: 100%; width: 100%" :options="mapOptions">
                            <gmap-marker v-if="showMarker" :position="curDeviceCoordinates" :icon="mtopMarker"/>
                        </gmap-map>
                    </div>
                    <div class="plateOverveiwFooter">
                        <div class="d-flex justify-content-between" style="gap: 12px;">
                            <b-button variant="success" @click="$emit('addToHotList')" style="flex: 1" size="sm"
                                      v-if="!isHotListHit">
                                Add to Hot List
                            </b-button>
                            <b-button v-if="ssDevice" variant="primary" @click="goToArchive" style="flex: 1" size="sm">
                                Archive Footage
                            </b-button>
                        </div>
                    </div>
                </div>
            </Transition>

        </div>
    </div>
</template>

<script>
import TextValueBox from "@/components/TextValueBox";
import googleMapSetup from '@/shared/mixins/googleMapSetup';

export default {
    name: "PlateOverview",
    props: {
        hitData: {
            type: Object,
            default: null
        },
        tenant: {
            type: String,
            default: ''
        },
        isHotListHit: {
            type: Boolean,
            default: false
        },
        ssDevice: {
            type: Object
        },
    },
    mixins: [googleMapSetup],
    components: {
        TextValueBox
    },
    data() {
        return {
            switching: false,
            isFootage: false,
            showMarker: false,
        }
    },
    mounted() {
        this.setIsFootage();
        this.$refs.deviceLocationMap.$mapPromise.then(() => {
            this.showMarker = true
        })
    },
    methods: {
        lprImage(image) {
            if (image !== undefined && image !== null) {
                return 'data:image/jpg;base64,' + image;
            }
        },
        goToArchive() {
            let timeStamp = this.hitData.timeStamp * 1000;
            let start = timeStamp - 1e4;
            let end = timeStamp + 1e4;
            let routeData = this.$router.resolve({
                name: 'Archives',
                query: {
                    tenant: this.tenant,
                    storageServer: this.ssDevice.getStorageServer(),
                    cameraName: this.hitData.cameraName,
                    startTime: start,
                    endTime: end,
                    sourceToken: '0'
                }
            })
            window.open(routeData.href, '_blank');
        },
        async setIsFootage() {
            if (this.ssDevice) {
                let timeStamp = this.hitData.timeStamp * 1000;
                let start = timeStamp - 10000;
                let end = timeStamp + 10000;
                let dayCode = Math.floor(new Date(start).valueOf() / 86400000);
                let hourCode = new Date(start).getUTCHours();
                this.ssDevice.setArchive();
                await this.ssDevice.createArchive(this.ssDevice, '0', undefined);
                let sourceId = this.ssDevice.getDeviceId() + '0' + undefined;
                await this.ssDevice.connectToArchive(sourceId);
                await this.ssDevice.getArchiveSegments(sourceId, dayCode, hourCode, async segments => {
                    if (!segments || !Array.isArray(segments) || segments.length === 0
                        || segments.find(timestamp => timestamp >= start && timestamp <= end) !== undefined) {
                        this.isFootage = false;
                    } else {
                        this.isFootage = true;
                    }
                    await this.ssDevice.closeArchiveConnection(sourceId);
                })
                this.isFootage = true;
            } else {
                this.isFootage = false;
            }
        },
        imageClicked() {
            this.$emit('imageClicked', {image: this.hitData.base64Image, plateNumber: this.hitData.plateNumber});
        }
    },
    computed: {
        curDeviceCoordinates() {
            if (!this.hitData.latitude || !this.hitData.longitude) {
                return {lat: null, lng: null};
            }
            return {
                lat: parseFloat(this.hitData.latitude),
                lng: parseFloat(this.hitData.longitude),
            };

        }
    },
    watch: {
        hitData: {
            deep: true,
            handler() {
                this.switching = true;
            }
        },
        switching() {
            if (this.switching === true) {
                this.$nextTick(() => {
                    if (this.ssDevice) {
                        this.setIsFootage()
                    }
                    this.switching = false;
                });
            }
        },
    }
}

</script>

<style scoped>
.plateOverviewContainer {
    margin: 6px;
    border-radius: 0.2rem;
    flex: 1;
    padding: 6px;
    width: calc(100% - 12px);
    background-color: #2f2f2f
}
.headerContainer {
    display: flex;
    justify-content: space-between;
}
.headerTitleText {
    font-weight: bolder;
    margin-right: 6px;
    transform: translateY(-3px);
}
.closeCircle {
    height: 15px;
    width: 15px;
    background-color: rgba(255, 255, 255, 0.17);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%
}
.closeIcon {
    transform: translateY(-1px);
    cursor: pointer;
}
.bodyContainer {
    margin: 6px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - 34px);
}
.imgContainer {
    display: flex;
    position: relative;
    justify-content: center;
    margin-bottom: 10px;
}
.plateImg {
    aspect-ratio: 16/9;
    width: 100%;
    border-radius: 0.2rem;
}
.bold {
    font-weight: bold !important;
}
.value {
    color: #cacaca;
}
.plateOverveiwFooter {
    display: flex;
    flex-direction: column;
}
.dataChange-enter-active {
    animation: dataChangeAnimation .6s ease;
}
@keyframes dataChangeAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>