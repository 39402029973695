import { render, staticRenderFns } from "./m-sidebar.vue?vue&type=template&id=3219d1ef&scoped=true&style=overflow%3A%20hidden"
import script from "./m-sidebar.vue?vue&type=script&lang=js"
export * from "./m-sidebar.vue?vue&type=script&lang=js"
import style0 from "./m-sidebar.vue?vue&type=style&index=0&id=3219d1ef&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3219d1ef",
  null
  
)

export default component.exports