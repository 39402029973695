<template>
    <div>
        <div class="border rounded d-flex flex-column" style="border-color: #171717 !important;">
            <div class="textStyle">
                {{ text }}
            </div>
            <div class="valueStyle">
                <slot>
                    {{ value }}
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TextValueBox",
    props: {
        text: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
.textStyle {
    border-bottom: 1px solid #171717;
    border-radius: 3px 3px 0 0;
    background-color: #171717;
    font-weight: 900;
    text-align: center;
    flex: 1;
}
.valueStyle {
    border-radius: 0 0 3px 3px;
    background-color: #2d2d2d;
    text-align: center;
    flex: 1
}
</style>