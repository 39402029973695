import gammaStore from "@/store/gammaStore";

function SmartSuiteLpr() {
    this.gamma = null;
    this.tenant = null;
}

SmartSuiteLpr.prototype.connect = async function (tenant) {
    this.setTenant(tenant);
    this.gamma = await gammaStore.dispatch('getANPRhub', tenant._id);
};

SmartSuiteLpr.prototype.close = async function () {
    await gammaStore.dispatch('closeANPRhub', this.tenant._id);
};

SmartSuiteLpr.prototype.lprEventResponseListener = async function (lprCallback) {
    await gammaStore.dispatch('setANPRhubListener', {
        _id: this.tenant._id, event: 'lprevents', callback: (event) => {
            lprCallback(event);
        }
    });
}

SmartSuiteLpr.prototype.setTenant = function (tenant) {
    this.tenant = tenant;
}

SmartSuiteLpr.prototype.getLprFeed = async function (callback) {
    await this.checkConnection();
    await gammaStore.dispatch('setANPRhubListener', {
        _id: this.tenant._id, event: 'lprevents', callback: (event) => {
            callback(event);
        }
    });
}

SmartSuiteLpr.prototype.addSourceListener = async function (eventName) {
    //the eventName should be in the format of the word 'tenant' + tenantId | ex: tenant1
    this.gamma.sendMessage('AddSourceListener', eventName);
}

SmartSuiteLpr.prototype.removeSourceListener = async function (eventName) {
    //the eventName should be in the format of the word 'tenant' + tenantId | ex: tenant1
    this.gamma.sendMessage('RemoveSourceListener', eventName);
}

SmartSuiteLpr.prototype.getMakeModelMap = async function () {
    return await this.gamma.sendMessage('GetMakeModelMap');
}

SmartSuiteLpr.prototype.getLprCameras = async function () {
    return await this.gamma.sendMessage('GetLprCameras');
};

SmartSuiteLpr.prototype.getLprCamerasCount = async function () {
    let temp = await this.gamma.sendMessage('GetLprCameras');
    return temp.length;
};

SmartSuiteLpr.prototype.getBlackListHits = async function () {
    return await this.gamma.sendMessage('GetBlacklistHits');
};

SmartSuiteLpr.prototype.getTotalHits = async function () {
    return await this.gamma.sendMessage('GetTotalHits');
};

SmartSuiteLpr.prototype.getTotalHitsByCamera = async function (cameraName) {
    return await this.gamma.sendMessage('GetTotalHitsByCamera', cameraName);
};

SmartSuiteLpr.prototype.getHitsLastWeek = async function () {
    return await this.gamma.sendMessage('GetHitsLastWeek');
};

SmartSuiteLpr.prototype.getHitsLastTen = async function (cameraName = null) {
    return await this.gamma.sendMessage('GetHitsLastTen', cameraName);
}

SmartSuiteLpr.prototype.getPagedLprEvents = async function (pageNumber, pageCount) {
    //check total to prevent divide by 0 error on server
    let total = await this.getTotalHits();
    if (total > 0) {
        return await this.gamma.sendMessage('GetPagedLprEvents', pageNumber, pageCount);
    } else {
        return [];
    }
};

SmartSuiteLpr.prototype.getPagedLprEventsByCamera = async function (cameraName, pageNumber, pageCount) {
    //check total to prevent divide by 0 error on server
    let total = await this.getTotalHitsByCamera(cameraName);
    if (total > 0) {
        return await this.gamma.sendMessage('GetPagedLprEventsByCamera', cameraName, pageNumber, pageCount);
    } else {
        return [];
    }
};

SmartSuiteLpr.prototype.getLprEventsByTimeRange = async function (startTime, endTime) {
    return await this.gamma.sendMessage('GetLprEventsByTimeRange', Math.floor(startTime/1000), Math.floor(endTime/1000));
};

SmartSuiteLpr.prototype.getLprEventsByCamera = async function (deviceName) {
    return await this.gamma.sendMessage('GetLprEventsByCamera', deviceName);
};

SmartSuiteLpr.prototype.getLprEventsByCameraTimeRange = async function (deviceName, startTime, endTime) {
    return await this.gamma.sendMessage('GetLprEventsByTimeRange', deviceName, Math.floor(startTime/1000), Math.floor(endTime/1000));
};

SmartSuiteLpr.prototype.getLprAverageConfidence = async function () {
    return await this.gamma.sendMessage('GetLprAverageConfidence');
}

SmartSuiteLpr.prototype.getLprEventsByPlateNumber = async function (plateNumber, startTime, endTime) {
    return await this.gamma.sendMessage('GetLprEventsByPlateNumber', plateNumber, Math.floor(startTime/1000), Math.floor(endTime/1000));
}

SmartSuiteLpr.prototype.getLprEventByMMC = async function (make, model, color, plateNumber, startTime, endTime, cameraName) {
    make = make !== '' ? make : 'unknown';
    model = model !== '' ? model : 'unknown';
    color = color !== '' ? color : 'unknown';
    plateNumber = plateNumber !== '' ? color : null;
    return await this.gamma.sendMessage('GetLprEventsByMMC', make, model, color, plateNumber, Math.floor(startTime/1000), Math.floor(endTime/1000), cameraName);
}

/* Hot List */
SmartSuiteLpr.prototype.getLprHotList = async function () {
    return await this.gamma.sendMessage('GetLprHotList')
}

SmartSuiteLpr.prototype.addToHotList = async function (plate, reason) {
    return await this.gamma.sendMessage('AddToHotList', {plate: plate, reason: reason})
}

SmartSuiteLpr.prototype.removeFromHotList = async function (entry) {
    return await this.gamma.sendMessage('RemoveFromHotList', entry)
}

SmartSuiteLpr.prototype.updateLprHot = async function (entry) {
    return await this.gamma.sendMessage('UpdateLprHot', entry)
}

SmartSuiteLpr.prototype.checkConnection = async function () {
    if (!this.gamma.isConnected()) {
        await this.connect(this.tenant);
    }
}
export default SmartSuiteLpr