import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';


Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDiNNTEEV7dTzuxZW1NCvR26RmHmOUhg2U',
        libraries: 'places'
    }
});

export default {
    data: () => {
        return {
            mapOptions: {
                styles: [
                    {
                        "elementType": "geometry",
                        "stylers": [
                            {"color": "#212121"}
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {"visibility": "off"}
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {"color": "#757575"}
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {"color": "#212121"}
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [
                            {"color": "#757575"}
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {"color": "#9e9e9e"}
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "stylers": [
                            {"visibility": "off"}
                        ]
                    },
                    {
                        "featureType": "administrative.locality",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {"color": "#bdbdbd"}
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "visibility": 'on',
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {"color": "#181818"}
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {"color": "#616161"}
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {"color": "#1b1b1b"}
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {"color": "#2c2c2c"}
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {"color": "#8a8a8a"}
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {"color": "#373737"}
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                            {"color": "#3c3c3c"}
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {"color": "#20a8d8"}
                        ]
                    },
                    {
                        "featureType": "road.highway.controlled_access",
                        "elementType": "geometry",
                        "stylers": [
                            {"color": "#4e4e4e"}
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {"color": "#616161"}
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {"color": "#757575"}
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {"color": "#000000"}
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {"color": "#3d3d3d"}
                        ]
                    }
                ],
                backgroundColor: 'none'
            },
            mtopMarker: {
                url: require('../../../public/img/deviceIcons/Marker_up.png'),
                scaledSize: null
            },
            mapReady: false
        }
    },
    async mounted() {
        this.mtopMarker.scaledSize = new (await this.google).maps.Size(30, 53)
    },
    computed: {
        async google() {
            // Use google. for functions that return to the html
            // Use (await this.google). if "TypeError: Cannot read properties of undefined" to avoid using await this.$gmapApiPromiseLazy()
            if (!this.mapReady) {
                await this.$gmapApiPromiseLazy();
                this.mapReady = true;
            }
            return window.google;
        }
    }
}