<template>
    <div>

        <!-- Image Viewer -->
        <b-modal :title="'Plate Number: '+selectedLicencePlateNumber" class="modal-dark" v-model="imageModal" ok-only size="lg"
                 hide-footer hide-header>
            <div style="margin: -16px" @click="imageModal = false">
                <img :src="selectedLicencePlateImage" style="width: 100%" alt="" class="rounded"/>
            </div>
        </b-modal>

        <!-- Main Page -->
        <b-card no-body style="height: calc(100vh - 200px)">

            <!-- Header -->
            <div slot="header">
                <div class="d-flex w-100 justify-content-between align-items-center flex-wrap">
                    <h5 style="margin-bottom: 0"><b>License Plate Recognition</b></h5>
                    <b-input-group prepend="Tenant" size="sm" style="width: fit-content">
                        <b-form-select v-model="curTenant" :options="tenants" size="sm"/>
                    </b-input-group>
                </div>
            </div>

            <!-- Tabs/Sidebar -->
            <div class="d-flex" style="overflow-y: hidden; height: calc(100vh - 122px); width: 100%">

                <!-- Tabs -->
                <MTabs :tabs="tabs" v-model="defaultTab" style="margin: 0 20px 20px 20px; flex: 1" @beforeTabChange="changeTab">

                    <!-- LPR Feed -->
                    <div slot="LPR Feed" style="overflow: auto">

                        <!-- Title/Search -->
                        <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 10px; width: 100%">

                            <!-- Title -->
                            <div class="d-flex">
                                <h3 style="margin: 0">LPR Feed:</h3>
                            </div>

                            <!-- Device Selection -->
                            <b-input-group prepend="Device" size="sm" style="width: fit-content">
                                <b-form-select v-model="lprFeedGroup" :options="[{text: 'All', value: 'all'}, ...cameraNameOptions]" size="sm"
                                               v-if="cameraNameOptions.length > 1"/>
                                <b-form-input v-else v-model="lprFeedGroup" size="sm" :disabled="true"/>
                            </b-input-group>

                        </div>


                        <!-- Table -->
                        <v-client-table style="width: 100%" class="dataTable" :columns="lprTable.columns" :data="lprFeedData"
                                        :options="{...lprTable.options, sortable: []}" :theme="tableTheme" @row-click="rowClicked">
                            <img slot-scope="props" slot="base64Image" :src="lprImage(props.row.base64Image)"
                                 style="height: 60px; max-width: 107px; border-radius: 0.2rem; cursor: pointer;" alt="plate"/>
                            <div slot="plateNumber" slot-scope="props" class="d-flex" style="gap: 3px">
                                <div style="font-size: 12px; font-weight: bold; transform: translateY(-.5px)">
                                    {{getStateCode(props.row.carState)}}
                                </div>
                                <div style="color: #9cc4ff; cursor: pointer" title="Search Plate"
                                     @click="searchPlate(props.row.plateNumber)">
                                    {{props.row.plateNumber}}
                                </div>
                            </div>
                            <div slot="makeModel" slot-scope="props">
                                {{props.row.make ? props.row.make + (props.row.model ? ' ' + props.row.model : '') : ''}}
                            </div>
                            <div slot="confidence" slot-scope="props">{{ props.row.confidence }}%</div>
                            <div slot="timeStamp" slot-scope="props">{{(new Date(props.row.timeStamp * 1000).toLocaleString()) }}</div>
                            <div slot="speed" slot-scope="props">
                                <div>{{ props.row.speed === '-1' ? '' : props.row.speed }}</div>
                            </div>
                            <div slot="color" slot-scope="props" class="d-flex align-items-center" v-if="props.row.color">
                                <div style="height: 12px; width: 12px; border: 1px solid #ffffff"
                                     :style="'background-color: ' + props.row.color.toLowerCase()"/>
                                <div style="line-height: 0">&nbsp;{{ props.row.color }}</div>
                            </div>
                        </v-client-table>

                    </div>

                    <!-- LPR Search -->
                    <div slot="LPR Search">

                        <!-- Title/Search Box -->
                        <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 10px; width: 100%">

                            <!-- Title -->
                            <div class="d-flex">
                                <h3 style="margin: 0">Search Results:</h3>
                            </div>

                            <!-- Search -->
                            <div class="d-flex" style="gap: 6px">
                                <b-input-group size="sm">
                                    <b-form-input style="width: fit-content" v-model="lprSearch" size="sm" @keypress.enter="getLpr" placeholder="Plate #"/>
                                    <b-input-group-append>
                                        <b-button variant="primary" size="sm" @click="getLpr" :disabled="lprSearchLoad"
                                                  :style="lprSearchLoad ? 'cursor: wait !important;' : ''">
                                            <div v-if="lprSearchLoad" class="d-flex">
                                                <CompactingRing style="width: 17px" width="14px" thickness="3px"/>&nbsp;Loading...
                                            </div>
                                            <div v-else>
                                                Search
                                            </div>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                                <div style="position: relative">
                                    <b-button size="sm" @click="lprFilterCollapse = !lprFilterCollapse" title="Filter">
                                        <fa-icon :icon="['fas', 'filter']" :style="lprFilterCollapse ? 'color: #A9A9A9' : ''"/>
                                    </b-button>
                                    <div style="position: absolute; transform: translate(-572px, 6px); width: 600px; background-color: #0d0d0d; z-index: 2; padding: 10px 0"
                                         class="rounded" v-if="lprFilterCollapse">
                                        <div class="d-flex justify-content-between" style="width: 100%; padding: 0 10px 10px 10px;">
                                            <div style="width: 100%; padding-right: 5px;">
                                                <b>After Date</b>
                                                <date-picker style="width: 100%;" v-model="lprAfterDate" :config="dateConfig"/>
                                            </div>
                                            <div style="width: 100%; padding-left: 5px;">
                                                <b>Before Date</b>
                                                <date-picker style="width: 100%;" v-model="lprBeforeDate" :config="dateConfig"/>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between" style="width: 100%; padding: 0 10px 10px 10px;">
                                            <div style="width: 100%; padding-right: 5px;">
                                                <b>Make: </b>
                                                <b-form-select style="width: 100%;" v-model="lprMake" :options="Object.keys(makeModel)"/>
                                            </div>
                                            <div style="width: 100%; padding: 0 5px;">
                                                <b>Model: </b>
                                                <b-form-select style="width: 100%;" :disabled="lprMake === ''" v-model="lprModel" :options="['', ...makeModel[lprMake]]"/>
                                            </div>
                                            <div style="width: 100%; padding-left: 5px;">
                                                <b>Color: </b>
                                                <b-input style="width: 100%;" v-model="lprColor"/>
                                            </div>
                                        </div>
                                        <div style="width: 100%; padding: 0 10px">
                                            <b>Camera: </b>
                                            <b-select style="width: 100%;" v-model="cameraName" :options="['', ...cameraNameOptions]"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <!-- Search Results -->
                        <div style="overflow: auto">
                            <MClientTable v-if="lprSearchingBy === 0 || lprSearchingBy === 5"
                                          :itemCount="lprSearchResultCount"
                                          :tableColumns="lprTable.columns"
                                          :tableOptions="lprPaginationOptions"
                                          :items="lprData"
                                          @getItemsByPage="getPlatesByPage"
                                          @row-click="rowClicked"
                                          :itemPerPageOptions="[]">
                                <template v-slot:base64Image="props">
                                    <img :src="lprImage(props.props.base64Image)"
                                         style="height: 60px; max-width: 107px; border-radius: 0.2rem" alt=""/>
                                </template>
                                <template v-slot:plateNumber="props">
                                    <div class="d-flex" style="gap: 3px">
                                        <div style="font-size: 12px; font-weight: bold; transform: translateY(-.5px)">
                                            {{getStateCode(props.props.carState)}}
                                        </div>
                                        <div style="color: #9cc4ff; cursor: pointer" title="Search Plate"
                                             @click="searchPlate(props.props.plateNumber)">
                                            {{props.props.plateNumber}}
                                        </div>
                                    </div>
                                </template>
                                <template v-slot:makeModel="props">
                                    {{props.props.make ? props.props.make + (props.props.model ? ' ' + props.props.model : '') : ''}}
                                </template>
                                <template v-slot:confidence="props">
                                    <div>{{ props.props.confidence }}%</div>
                                </template>
                                <template v-slot:timeStamp="props">
                                    <div>{{(new Date(props.props.timeStamp * 1000).toLocaleString()) }}</div>
                                </template>
                                <template v-slot:speed="props">
                                    <div>{{ props.props.speed === '-1' ? '' : props.props.speed }}</div>
                                </template>
                                <template v-slot:color="props">
                                    <div v-if="props.props.color" class="d-flex align-items-center">
                                        <div style="height: 12px; width: 12px; border: 1px solid #ffffff"
                                             :style="'background-color: ' + props.props.color.toLowerCase()"/>
                                        <div style="line-height: 0">&nbsp;{{ props.props.color }}</div>
                                    </div>
                                </template>
                            </MClientTable>
                            <v-client-table v-else style="width: auto" class="dataTable" :columns="lprTable.columns"
                                            @row-click="rowClicked" :theme="tableTheme" :data="lprData" ref="lprSearchResults"
                                            :options="{...lprTable.options, sortable: ['cameraName', 'plateNumber', 'makeModel', 'color', 'speed', 'confidence', 'timeStamp']}">
                                <img slot-scope="props" slot="base64Image" :src="lprImage(props.row.base64Image)"
                                     style="height: 60px; max-width: 107px; border-radius: 0.2rem; cursor: pointer;" alt="Plate Image"/>
                                <div slot="plateNumber" slot-scope="props" class="d-flex" style="gap: 3px">
                                    <div style="font-size: 12px; font-weight: bold; transform: translateY(-.5px)">
                                        {{getStateCode(props.row.carState)}}
                                    </div>
                                    <div style="color: #9cc4ff; cursor: pointer" title="Search Plate"
                                         @click="searchPlate(props.row.plateNumber)">
                                        {{props.row.plateNumber}}
                                    </div>
                                </div>
                                <div slot="makeModel" slot-scope="props">
                                    {{props.row.make ? props.row.make + (props.row.model ? ' ' + props.row.model : '') : ''}}
                                </div>
                                <div slot="confidence" slot-scope="props">{{ props.row.confidence }}%</div>
                                <div slot="timeStamp" slot-scope="props">{{(new Date(props.row.timeStamp * 1000).toLocaleString()) }}</div>
                                <div slot="speed" slot-scope="props">
                                    <div>{{ props.row.speed === '-1' ? '' : props.row.speed }}</div>
                                </div>
                                <div slot="color" slot-scope="props" class="d-flex align-items-center" v-if="props.row.color">
                                    <div style="height: 12px; width: 12px; border: 1px solid #ffffff"
                                         :style="'background-color: ' + props.row.color.toLowerCase()"/>
                                    <div style="line-height: 0">&nbsp;{{ props.row.color }}</div>
                                </div>
                            </v-client-table>
                        </div>

                    </div>

                    <!-- Hot List -->
                    <div slot="Hot List">
                        <HotList :defaultTab="defaultTab" :hotList="hotList" :ssLPR="lpr" @updateHotList="updateHotList"
                                 v-model="plateOverview.addPlate" @searchPlate="searchPlate"/>
                    </div>

                </MTabs>

                <!-- Sidebar -->
                <MSideBar :isOpen="openSidebar">

                    <!-- Plate Overview -->
                    <Transition :name="curTab === 'LPR Feed' ? 'changeSideBar' : ''">
                        <PlateOverview v-if="plateOverview.open" :hitData="plateOverview.data" @close="closePlateOverview"
                                       :isHotListHit="plateOverview.data && isPlateOnHotList(plateOverview.data.plateNumber)"
                                       class="d-flex flex-column w-100 h-100 changeSideBar position-absolute"
                                       @addToHotList="addToHotList" style="padding: 6px 6px 6px 0; z-index: 5" :tenant="curTenant._id"
                                       @imageClicked="openVehicleImageModal"
                                       :ssDevice="plateOverview.ssDevice"/>
                    </Transition>

                    <!-- Info Boxes -->
                    <div v-if="curTab === 'LPR Feed'" class="d-flex flex-column w-100 h-100 changeSideBar" style="padding: 6px 6px 6px 0">

                        <!-- Total Plates -->
                        <div style="flex: 1; margin: 6px">
                            <div class="widgetBox" style="background-color: #007bff; flex: 1; display: flex; flex-direction: column">
                                <div class="widgetContent">
                                    <div/>
                                    <div style="font-size: 55px; line-height: 1; text-align: center; font-weight: bold" >
                                        <fa-icon :icon="['fas', 'id-card']" class="cursorDefault"/>
                                        <div v-if="cardData.weekPlateNum === null" style="font-size: 30px; height: 35px">
                                            <CompactingRing width="20px" thickness="3px" color="#ffffff"/>&nbsp;Loading...
                                        </div>
                                        <div v-else>{{ cardData.weekPlateNum.toLocaleString("en-US") }}</div>
                                    </div>
                                    <div style="font-size: 35px; line-height: .8; text-align: center">
                                        Plates Total
                                    </div>
                                </div>
                                <div class="font-weight-bolder font-xs d-flex justify-content-end" style="color: #b9b9b9">
                                    Last Week:&nbsp;
                                    <div v-if="cardData.lastWeekPlateNum === null" class="m-0 font-xs font-weight-bold">
                                        <CompactingRing width="10px" thickness="2px" color="#b9b9b9"/>&nbsp;Loading...
                                    </div>
                                    <span v-else>{{ cardData.lastWeekPlateNum }}</span>
                                </div>
                            </div>
                        </div>

                        <!-- Average Confidence -->
                        <div style="flex: 1; margin: 6px">
                            <div class="widgetBox" style="background-color: #54a4ff; flex: 1; display: flex; flex-direction: column">
                                <div class="widgetContent">
                                    <div/>
                                    <div style="font-size: 55px; line-height: 1; text-align: center; font-weight: bold" >
                                        <fa-icon :icon="['fas', 'percent']" class="cursorDefault"/>
                                        <div v-if="cardData.avgConfidence === null" style="font-size: 30px; height: 35px">
                                            <CompactingRing width="20px" thickness="3px" color="#ffffff"/>&nbsp;Loading...
                                        </div>
                                        <div v-else>{{ cardData.avgConfidence.toFixed(2) }}</div>
                                    </div>
                                    <div style="font-size: 35px; line-height: .8; text-align: center">
                                        Average Confidence
                                    </div>
                                </div>
                                <div/>
                            </div>
                        </div>

                        <!-- Average Confidence -->
                        <div style="flex: 1; margin: 6px">
                            <div class="widgetBox" style="background-color: #007bff; flex: 1; display: flex; flex-direction: column">
                                <div class="widgetContent">
                                    <div/>
                                    <div style="font-size: 55px; line-height: 1; text-align: center; font-weight: bold">
                                        <fa-icon :icon="['fas', 'camera']" class="cursorDefault" style="margin-right: 10px;"/>
                                        <div v-if="cardData.cameraNum === null" style="font-size: 30px; height: 35px">
                                            <CompactingRing width="20px" thickness="3px" color="#ffffff"/>&nbsp;Loading...
                                        </div>
                                        <span v-else>{{ cardData.cameraNum.toLocaleString("en-US") }}</span>
                                    </div>
                                    <div style="font-size: 35px; line-height: .8; text-align: center">
                                        LPR Camera{{cardData.cameraNum === 1 ? "" : "s"}}
                                    </div>
                                </div>
                                <router-link to="/devices">
                                    <div class="font-weight-bolder font-xs d-flex justify-content-end" style="color: #b9b9b9">
                                        Go To Devices
                                    </div>
                                </router-link>
                            </div>
                        </div>

                    </div>

                </MSideBar>

            </div>

        </b-card>
    </div>
</template>

<script>
import Vue from 'vue';
import {ClientTable} from 'vue-tables-2';
import tenantStore from "@/store/tenantStore";
import SmartSuiteLpr from "@/shared/smartsuite_services/smartsuite_lpr";
import PubSub from "pubsub-js";
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import dateRangeSelect from "@/components/date-range-select.vue";
import MClientTable from "@/components/m-client-table.vue";
import CompactingRing from "@/components/loading_icons/compacting-ring.vue";
import HotList from "@/views/expansion_modules/LPR/HotList.vue";
import MTabs from "@/components/m-tabs.vue";
import MSideBar from "@/components/m-sidebar.vue";
import PlateOverview from "@/views/expansion_modules/LPR/PlateOverview.vue";
import SmartSuiteArchive from "@/shared/smartsuite_services/smartsuite_archive";
import SmartSuiteDevice from "@/shared/smartsuite_services/smartsuite_device";
import {states as stateCodes} from "@/shared/states";

Vue.use(ClientTable);

const NO_FILTER = 0;
const TEXT_ONLY = 1;
const TIME_ONLY = 2;
const TEXT_AND_TIME = 3;
const MMC = 4;
const CAMERA_NAME = 5;

export default {
    name: "LPR",
    components: {
        PlateOverview,
        MSideBar,
        HotList,
        datePicker,
        dateRangeSelect,
        MClientTable,
        CompactingRing,
        MTabs
    },
    data: () => {
        return {
            stateCodes,
            ssArchive: null,
            ssArchiveDevices: [],
            plateOverview: {
                open: false,
                data: null,
                addPlate: null,
                ssDevice: null,
            },
            curTab: 'LPR Feed',
            openSidebar: true,
            tabs: [
                'LPR Feed',
                'LPR Search',
                'Hot List',
            ],
            defaultTab: 'LPR Feed',
            pubsub: null,
            imageModal: false,
            selectedLicencePlateImage: null,
            selectedLicencePlateNumber: null,
            tableTheme: 'bootstrap4',
            lprBeforeDate: null,
            lprAfterDate: null,
            lprMake: '',
            lprModel: '',
            lprColor: '',
            lprFilterCollapse: false,
            lprPaginationOptions: {
                headings: {
                    'base64Image': 'Image',
                    'timeStamp': 'Capture Date',
                    'plateNumber': 'Plate',
                    'makeModel': 'Make/Model',
                    'color': 'Color',
                    'speed': 'Speed',
                    'confidence': 'Confidence',
                },
                filterable: false,
                skin: 'table table-striped table-hover',
            },
            lprTable: {
                columns: ['base64Image', 'cameraName', 'plateNumber', 'makeModel', 'color', 'speed', 'confidence', 'timeStamp'],
                options: {
                    orderBy: {column: 'timeStamp', ascending: false},
                    headings: {
                        'base64Image': 'Image',
                        'cameraName': 'Camera',
                        'plateNumber': 'Plate',
                        'makeModel': 'Make/Model',
                        'color': 'Color',
                        'speed': 'Speed',
                        'confidence': 'Confidence',
                        'timeStamp': 'Capture Date',
                    },
                    filterable: false,
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll',
                    },
                    skin: 'table table-striped table-hover',
                    perPage: 10,
                    perPageValues: [],
                },
            },
            lprData: [],
            lprSearch: "",
            lprSearchLoad: false,
            lprFeedData: [],
            cardData:   {
                cameraNum: null,
                recentBlacklistHits: null,
                blacklistHits: null,
                weekPlateNum: null,
                lastWeekPlateNum: null,
                avgConfidence: null,
                plateNum: null
            },
            dateConfig: {
                format: 'MM/DD/YYYY h:mm:ss a',
                useCurrent: false,
                maxDate: Date.now() + 10000,
                showClear: true
            },
            curTenant: '',
            tenants: [],
            lpr: null,
            lprSearchPageNumber: 1,
            lprSearchResultCount: 0,
            lprSearchingBy: null,
            makeModel: {},
            makeModelInterval: null,
            cameraName: undefined,
            cameraNameOptions: [],
            lprFeedGroup: 'LPR Cam',
            firstMessage: true,
            hotList: [],
            currentTenantId: null
        };
    },
    async created() {
        this.lprTable.options.rowClassCallback = (row) => {
            return this.isPlateOnHotList(row.plateNumber) ? 'hotListHit' : '';
        }
        this.lprPaginationOptions.rowClassCallback = (row) => {
            return this.isPlateOnHotList(row.plateNumber) ? 'hotListHit' : '';
        }
        this.lpr = await new SmartSuiteLpr();
        await this.getTenants();
    },
    beforeDestroy() {
        clearInterval(this.makeModelInterval);
        PubSub.unsubscribe(this.pubsub);
    },
    methods: {
        searchPlate(plate) {
            this.defaultTab = 'LPR Search';
            this.lprSearch = plate;
            this.getLpr(plate);
        },
        addToHotList() {
            this.defaultTab = 'Hot List';
            this.plateOverview.addPlate = this.plateOverview.data.plateNumber;
        },
        getStateCode(state) {
            if (!state) return '';
            if (!this.stateCodes[state]) return '[' + state + ']';
            return '[' + this.stateCodes[state] + ']';
        },
        closePlateOverview() {
            if (this.curTab !== 'LPR Feed') {
                this.openSidebar = false;
            }
            this.plateOverview.open = false;
        },
        async rowClicked(data) {
            if (this.plateOverview.data && this.plateOverview.data.timeStamp === this.lprFeedData[data.index-1].timeStamp
                && this.plateOverview.open) {
                this.closePlateOverview();
            } else {
                let oldData = this.plateOverview.data
                this.plateOverview.data = this.lprFeedData[data.index-1];
                if (!oldData || oldData.cameraName !== data.row.cameraName) {
                    let result = this.ssArchiveDevices.find(_ => _.deviceFriendlyName === this.plateOverview.data.cameraName);
                    if (result) {
                        this.plateOverview.ssDevice = await new SmartSuiteDevice(result)
                    } else {
                        this.plateOverview.ssDevice = null
                    }
                }
                if (!this.openSidebar) {
                    this.openSidebar = true;
                }
                this.plateOverview.open = true;
            }
        },
        changeTab(oldTabName, newTabName) {
            this.curTab = newTabName;
            if (oldTabName === 'LPR Feed' && !this.plateOverview.open) {
                this.openSidebar = false;
            } else if (newTabName === 'LPR Feed') {
                this.lprSearchingBy = null
                if (!this.openSidebar) {
                    this.plateOverview.open = false;
                }
                this.openSidebar = true;
            }
        },
        async getPlatesByPage(data) {
            if (this.lprSearchingBy === NO_FILTER) {
                this.lprData = await this.lpr.getPagedLprEvents(data.page, 10);
            } else if (this.lprSearchingBy === CAMERA_NAME) {
                this.lprData = await this.lpr.getPagedLprEventsByCamera(this.cameraName, data.page, 10);
            }
        },
        getTenants() {
            tenantStore.dispatch('getTenants').then(async response1 => {
                let count = 0;
                for (const tenant of response1) {
                    this.tenants.push({
                        value: tenant,
                        text: tenant.tenantName
                    });
                    this.tenants.sort(function (a, b) {
                        return a.text.localeCompare(b.text);
                    });
                    count++;
                    if (count === response1.length) {
                        this.curTenant =  this.tenants[0].value;
                    }
                }
            });
            this.tenants.sort(function (a, b) {
                return a.text.localeCompare(b.text);
            });
        },
        async getLprFeed() {
            if (this.lprFeedGroup === 'all') {
                let temp = await this.lpr.getTotalHits();
                if (temp < 10) {
                    this.lprFeedData = await this.lpr.getPagedLprEvents(1, temp);
                } else {
                    this.lprFeedData = await this.lpr.getHitsLastTen();
                }
            } else {
                let temp = await this.lpr.getTotalHitsByCamera(this.lprFeedGroup);
                if (temp < 10) {
                    this.lprFeedData = await this.lpr.getPagedLprEventsByCamera(this.lprFeedGroup, 1, temp);
                } else {
                    this.lprFeedData = await this.lpr.getHitsLastTen(this.lprFeedGroup);
                }
            }
        },
        isPlateOnHotList(plate) {
            return !!(~this.hotList.findIndex(hlPlate => hlPlate.plate === plate));
        },
        lprImage(image) {
            if (image !== undefined && image !== null) {
                return 'data:image/jpg;base64,' + image;
            }
        },
        openVehicleImageModal(data) {
            this.selectedLicencePlateImage = 'data:image/jpg;base64,' + data.image;
            this.selectedLicencePlateNumber = data.plateNumber;
            this.imageModal = true;
        },
        async getLpr(plate) {
            plate = ((typeof plate === 'string') ? plate : null);
            this.lprSearchLoad = true;
            if (!plate && (this.lprMake !== '' || this.lprColor !== '')) {
                this.lprSearchingBy = MMC;
                this.lprData = await this.lpr.getLprEventByMMC(
                    this.lprMake, this.lprModel, !this.lprColor ? null : this.lprColor, this.lprSearch,
                    this.lprAfterDate === null ? 0 : new Date(this.lprAfterDate).valueOf(),
                    this.lprBeforeDate === null ? Date.now() : new Date(this.lprBeforeDate).valueOf(),
                    this.cameraName ? this.cameraName : null
                );
            } else if (this.lprSearch !== '') {
                this.lprSearchingBy = TEXT_AND_TIME;
                this.lprData = await this.lpr.getLprEventsByPlateNumber(
                    plate ? plate : this.lprSearch,
                    this.lprAfterDate === null ? 0 : new Date(this.lprAfterDate).valueOf(),
                    this.lprBeforeDate === null ? Date.now() : new Date(this.lprBeforeDate).valueOf()
                );
            } else if (this.lprBeforeDate !== null || this.lprAfterDate !== null) {
                this.lprSearchingBy = TIME_ONLY;
                this.lprData = await this.lpr.getLprEventsByTimeRange(
                    this.lprAfterDate === null ? 0 : new Date(this.lprAfterDate).valueOf(),
                    this.lprBeforeDate === null ? Date.now() : new Date(this.lprBeforeDate).valueOf()
                );
            } else if (this.cameraName || this.cameraName === null) {
                this.lprSearchingBy = CAMERA_NAME;
                this.lprSearchResultCount = await this.lpr.getTotalHitsByCamera(this.cameraName);
                this.lprSearchPageNumber = 1;
                if (this.cardData.weekPlateNum < 10) {
                    this.lprData = await this.lpr.getPagedLprEventsByCamera(this.cameraName, 1, this.lprSearchResultCount);
                } else {
                    this.lprData = await this.lpr.getPagedLprEventsByCamera(this.cameraName, 1, 10);
                }
            } else {
                this.lprSearchingBy = NO_FILTER;
                this.lprSearchResultCount = await this.lpr.getTotalHits();
                this.lprSearchPageNumber = 1;
                if (this.cardData.weekPlateNum < 10) {
                    this.lprData = await this.lpr.getPagedLprEvents(1, this.cardData.weekPlateNum.length);
                } else {
                    this.lprData = await this.lpr.getPagedLprEvents(1, 10);
                }
            }
            this.lprSearchLoad = false;
        },
        async changeLprFeed(oldVal) {
            this.hotList = await this.lpr.getLprHotList();
            await this.lpr.removeSourceListener(oldVal === 'all' ? `tenant${this.curTenant._id}` : oldVal);
            await this.getLprFeed();
            await this.lpr.addSourceListener(this.lprFeedGroup === 'all' ? `tenant${this.curTenant._id}` : this.lprFeedGroup);
            await this.lpr.getLprFeed(data => {
                this.lprFeedData.pop();
                this.lprFeedData.unshift(data);
            });
        },
        async setCardData() {
            this.hotList = await this.lpr.getLprHotList();
            this.cameraNameOptions = await this.lpr.getLprCameras();
            if (this.firstMessage === true) {
                if (this.cameraNameOptions.length === 1) {
                    this.lprFeedGroup = this.cameraNameOptions[0];
                } else {
                    this.lprFeedGroup = 'all';
                }
                this.firstMessage = false;
            }
            this.cardData.weekPlateNum = await this.lpr.getTotalHits();
            this.cardData.lastWeekPlateNum = await this.lpr.getHitsLastWeek();
            this.cardData.blacklistHits = await this.lpr.getBlackListHits();
            this.cardData.cameraNum = await this.lpr.getLprCamerasCount();
            this.cardData.avgConfidence = await this.lpr.getLprAverageConfidence();
        },
        async updateHotList() {
            this.hotList = await this.lpr.getLprHotList();
        },
        async setSSArchive() {
            if (this.ssArchive !== null) {
                this.ssArchive.close();
            }
            this.ssArchive =  await new SmartSuiteArchive(null, null, null, this.curTenant);
            this.ssArchiveDevices = []
            await this.ssArchive.connect();
            let ssArchiveDevices = []
            await this.ssArchive.getStorageServers(async storageServers => {
                await this.ssArchive.streamAllDeviceStoresAsync(storageServers, async devices => {
                    if (devices === 'done') {
                        this.ssArchiveDevices = ssArchiveDevices;
                        return;
                    }
                    ssArchiveDevices = ssArchiveDevices.concat(devices.map(device => Object.assign(device, {
                        _id: device.storeName,
                        tenantId: this.curTenant._id,
                        tenant: this.curTenant._id,
                        url: this.curTenant.url,
                        archive: true,
                        friendlyName: device.deviceFriendlyName,
                        streamServer: device.streamServer + 'archive/',
                    })));
                });
            })
        }
    },
    watch: {
        lprSearchingBy () {
            if (this.lprSearchingBy === null) {
                try {
                    this.$refs.lprSearchResults.setOrder('timeStamp', false);
                } catch (e){}
            }
        },
        lprMake() {
            this.lprModel = '';
        },
        lprSearch() {
            this.lprSearch = this.lprSearch.toUpperCase();
        },
        lprColor() {
            this.lprColor = this.lprColor.toUpperCase();
        },
        async lprFeedGroup(newVal, oldVal) {
            await this.changeLprFeed(oldVal);
        },
        async curTenant() {
            await this.lpr.setTenant(this.curTenant);
            await this.lpr.connect(this.curTenant);
            await this.setSSArchive();
            this.hotList = await this.lpr.getLprHotList();
            this.lpr.getMakeModelMap().then(response => {
                if (response !== undefined) {
                    this.makeModel = response;
                }
            });
            if (this.makeModelInterval)  {
                clearInterval(this.makeModelInterval);
            }
            this.makeModelInterval = setInterval(() => {
                this.lpr.getMakeModelMap().then(response => {
                    if (response !== undefined) {
                        this.makeModel = response;
                    }
                });
            }, 15000);
            await this.getLprFeed();
            await this.lpr.addSourceListener('tenant'+this.curTenant._id);
            await this.lpr.getLprFeed(data => {
                data.hotList = this.isPlateOnHotList(data.plateNumber);
                this.lprFeedData.pop();
                this.lprFeedData.unshift(data);
                this.setCardData()
            });
            this.firstMessage = true;
            this.lpr.lprEventResponseListener(this.setCardData());  // Constant Updates
        }
    }
};
</script>

<style scoped>
.widgetBox {
    border-radius: 0.3rem;
    width: 100%;
    height: 100%;
    padding: 5px;
}
.widgetContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.changeSideBar-enter-active {
    transition: all 0.3s ease-out;
}
.changeSideBar-leave-active {
    transition: all 0.3s ease-in;
}
.changeSideBar-enter,
.changeSideBar-leave-to {
    transform: translateX(100%);
}
</style>
<style>
.hotListHit {
    background-color: rgb(71, 33, 37) !important;
}
</style>